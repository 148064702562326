body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --pale-grey-bg-color: #f6f6f7;
  --blue-grey: #2a3244;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

#loginform {
  margin-top: 50%;
}

/* Filters Section */
#avb-filters {
  margin: 12px 0px;
  .search-icon {
    position: absolute;
    margin: 10px;
  }
  .search-input {
    padding-left: 35px;
  }
  [id^="react-select-"] {
    z-index: 20;
  }
}

.cstm-signout-link {
  font-size: 12px;
  margin-left: 32px;
}

.header_Styles {
  margin: 8px 28px;
}

/* Input field CSS override */
#avb-filters .css-1s2u09g-control,
#avb-filters .form-control {
  border: none;
  text-align: left;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
.col {
  text-align: left;
}

.admin-body {
  padding: 0 5%;
  background: #f8f9fa;
  border-top: 1px solid #ced4da;
}

//shared
.anticon.anticon-down {
  font-size: 14px !important;
}

#avb-screening {
  .submit-again,
  .increase-deposit {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: var(--blue-grey);
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
    span {
      display: inline-block;
      margin-right: 4px;
      vertical-align: bottom;
    }
  }
  .blue-card {
    height: fit-content;
    min-height: 250px;
  }
  .alert-type-address {
    text-transform: none;
    font-weight: 700;
    color: #f5222d;
  }
  .alert-type-ssn {
    text-transform: none;
    font-weight: 700;
    color: red;
  }
  .tags {
    display: flex;
  }
}

.ant-collapse-header {
  font-size: 18px;
  background: #ffffff;
  font-weight: 500;
}

.ant-collapse-header .ant-collapse-arrow {
  font-size: 18px !important;
  vertical-align: 1px !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: white;
  border-top: 0;
}

.context {
  position: absolute;
  background: #fff;
  padding: 1em;
  right: 2em;
  border: solid 1px #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.avb-context.dropdown-toggle::after {
  display: none !important;
}

.dropdown {
  text-align: right;
}

.meatball-menu {
  font-size: 1.3rem;
  font-weight: 400;
}

.avb-context.ellipse {
  text-align: end;
  padding-right: 16px;
}

.text-color {
  color: #ffa39e;
}

.text-uppercase {
  text-transform: uppercase;
  text-align: left;
}
.blue-card {
  padding: 20px;
  background-color: var(--blue-grey);
  margin: 15px 0;
  color: #ffffff;
  text-align: left;
  letter-spacing: 1px;
  border-radius: 2px;

  h3 {
    color: inherit;
    border-bottom: solid 1px #ffffff;
    padding: 10px 0;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
  }
  .tagPlaceHolder {
    span {
      display: inline-block;
      font-size: 14px;
    }

    .anticon svg {
      margin-bottom: 4px;
      margin-bottom: 6px;
      font-size: 10px;
    }
  }
  span {
    font-size: 12px;
    display: block;
  }
}
.avb-g-tag {
  width: 40px;
  margin: 0 5px;
  padding: 0px 5px;
  background: url("./components/Shared/icons/icon-info-cirlce.svg") no-repeat
    center right 5px;
  background-color: #525761;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  font-weight: 600;
}

.success-icon {
  background: url("./components/Shared/icons/icon-check-circle.svg") no-repeat
    center right 5px;
}
.first-time-user {
  height: 360px;
  padding: 96px 206px 95.6px 255px;
  border-radius: 3px;
  border: dashed 2px #dcdddf;
  margin: 1rem auto;
  font-size: 37px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #dcdddf;

  svg {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 900px) {
    padding-top: 48px;
    font-size: 32px;
  }
}
.avb-help-request-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  background: red;
  text-align: center;
  color: #fff;
}
.request-flags {
  list-style: none;
  margin: 0;
  padding: 5px;
  text-align: left;
  li {
    padding: 0;
    cursor: pointer;
  }

  .request-flag-icon {
    margin-right: 10px;
  }
}
// MODAL DIALOG
#avb-applicant-information {
  max-width: 750px;
}
.modal-content {
  color: #31343a;
  //font-family: 'ProximaNova';
  .btn-primary {
    background-color: #1d2f5c;
  }
}
.btn-primary {
  background-color: #1d2f5c;
  margin: 0.5rem;
}
.btn-primary:disabled {
  background-color: grey;
  font-weight: bold;
  color: black;
  cursor: not-allowed;
}
div.modal-header {
  font-size: 21px;
  border-bottom: solid 1px #dcdddf;
  padding: 1rem 2rem;
}
.modal-dialog {
  max-width: none !important;
  width: 579px !important;
}
.modal-body {
  margin: 0;
  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 30px;
    margin-top: 22px;
    margin-left: 21px;
    span {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 1rem;
      background: #72767f;
      color: #ffffff;
      border-radius: 50%;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      vertical-align: middle;
    }
  }
  .details {
    margin: 30px 60px 55px 60px;
    h4 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding-bottom: 22px;
    }
    .row {
      font-size: 16px;
      margin-bottom: 30px;
      font-weight: 600;
      label {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 12px;
      }
      .form {
        text-align: right;
        max-width: 180px;
        label {
          font-size: 16px;
          font-weight: 500;
          text-align: right;
        }
      }
    }

    .status {
      padding-left: 0px;
      font-size: 14px;
      font-weight: 300;
      label {
        font-weight: bold;
        display: flex;
        span {
          width: 17px;
          height: 17px;
          color: #ffffff;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1rem;
          background-color: red;
        }
        .complete,
        .completed {
          background-color: #52c41a;
        }
        .in-process {
          background-color: red;
        }
      }
    }

    table {
      thead {
        background-color: #f6f6f7;

        th {
          font-weight: normal;
          padding: 5px 10px;
          font-size: 12px;
          white-space: nowrap;
          width: 25%;
        }
      }

      tbody td {
        padding: 5px 10px;
        font-size: 14px;
        vertical-align: top;
      }

      tbody a {
        font-weight: bold;
        color: var(--blue-grey);
        text-decoration: none;
      }
    }

    .required label::before {
      content: "*";
      color: red;
      display: block;
      float: left;
      padding: 5px 2px 0 0;
      font-size: 12px;
    }

    .additional-comments {
      word-break: break-all;
      overflow-wrap: break-word;
    }
  }
}
.cancel-icon {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  background-color: orange;
  text-align: center;
  line-height: 25px;
}
div.modal-footer {
  border-top: 1px solid #dcdddf;
  button,
  button.primary,
  button.secondary {
    font-size: 14px;
    font-family: var(--bs-body-font-family);
  }
  button.primary,
  button.primary:hover {
    background-color: var(--blue-grey);
  }

  .btn-blue-grey {
    background-color: #41454d;
  }
}
div.alert {
  text-align: left;
  padding: 10px;
  padding-left: 35px;
  color: #31343a;
  font-size: 14px;
  button.alert-dismissible .btn-close {
    padding: 15px;
  }
}
.alert-danger {
  background: #ffa39e url("./components/Shared/icons/icon-close-circle.svg")
    no-repeat 10px center;
  border-color: #ffa39e;
}
div.alert-success {
  background: #f6ffed url("./components/Shared/icons/icon-check-circle.svg")
    no-repeat 10px center;
  border-color: #b7eb8f;
}
.alert-dismissible button.btn-close {
  padding: 15px 10px;
  width: 10px;
  font-size: 10px;
  height: 10px;
}
.divider {
  display: block;
  height: 1px;
  background: var(--blue-grey);
}
.cancel {
  font-family: var(--bs-body-font-family);
  margin: 1rem 0;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  background-color: #ffffff;
  padding-left: 35px;
  background: url("./components/Shared/icons/icon-close-circle.svg") #ffffff
    no-repeat left 10px center;
}
.generate {
  font-family: var(--bs-body-font-family);
  margin: 1rem 0;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  background-color: #ffffff;
  padding-left: 35px;
  background: url("./components/Shared/icons/icon-info-cirlce.svg") #ffffff
    no-repeat left 10px center;
}
div.block-labels.details {
  margin: 2rem;
  .col {
    label {
      display: block;
    }
  }
  .subtitle {
    font-size: 20px;
  }
}
div.border-bottom {
  border-bottom: solid 1px;
}
div.border-top {
  border-top: solid 1px;
}
.modal-footer a {
  margin-right: 2rem;
}
.error-message {
  padding-left: 3rem;
  background: url("./components/Shared/icons/icon-close-circle.svg") #ffffff
    no-repeat left top;
  background-size: 1.5rem;
  h2 {
    font-size: 21px;
    margin-left: 0;
    font-family: "ProximaNova-Bold";
  }
}
.help-requests {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0.5rem 1rem;
    background: #fff1f0;
    border: solid 1px #ffa39e;
    border-radius: 6px;
    list-style: none;
    display: flex;
    flex: 100%;
    flex-direction: row;
    margin-bottom: 10px;
    .request-topic {
      margin-left: 1rem;
      flex: 1;
      text-align: left;
      font-weight: 500;
      .topic {
        color: #1d2f5c;
        font-size: 1rem;
      }
    }
    .resolve {
      border: none;
      font-size: 14px;
      background: #fff;
      border-radius: 4px;
      font-weight: bold;
    }

    svg {
      margin-top: 4px;
    }
  }
}
.warning-modal {
  .modal-body {
    padding: 2rem;
    padding-bottom: 0;
  }
  .display-line-break {
    white-space: pre-line;
  }
}
.cancel-btn {
  background: #eeeeee !important;
  border: none 0px !important;
  color: #212529 !important;
}
.modal-header .btn-close {
  content: "x";
  width: 0.5em;
  height: 0.5em;
  font-size: 0px;
  background-size: cover;
}

.cstm-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anticon svg {
  margin-bottom: 4px;
}
.ant-tag {
  color: #31343a !important;
  &.credit-pending {
    background-color: #e8eaee;
    color: #31343a;
    border: solid 1px #bbc0ce;
    span {
      color: #31343a !important;
    }
  }
}

.help-request-email-tooltip {
  .ant-tooltip-arrow {
    display: none !important;
  }
  .ant-tooltip-inner {
    width: fit-content;
    background-color: #cdcdcd !important;
    color: black;
  }
}

.tag-in-progress,
.tag-further-review {
  .bi.bi-three-dots {
    color: dodgerblue;
  }
}

.tag-in-problem-resolution {
  .bi.bi-three-dots {
    color: purple;
  }
}

.hold-clock-detail-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;

  .hold-clock-color-green {
    color: unset !important;
    background-color: #effce8;
    font-size: 12px;
    border: solid 1px #52c41a;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    vertical-align: middle;

    span.anticon-clock-circle {
      color: #52c41a !important;
      font-weight: bold;
      font-size: 16px;
      svg {
        margin: 0px;
      }
    }
    span.clock {
      color: #606161;
      vertical-align: middle;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: 5px;
      margin-right: 15px;
    }
    span.anticon-info-circle {
      color: #b9bcbf;
      font-size: 14px;
      font-weight: bold;
      svg {
        margin-bottom: 1px;
      }
    }
  }
  .hold-clock-color-yellow {
    color: unset !important;
    background-color: #fffce6;
    font-size: 12px;
    border: solid 1px #fddb00;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    vertical-align: middle;

    span.anticon-clock-circle {
      color: #fddb00 !important;
      font-weight: bold;
      font-size: 16px;
      svg {
        margin: 0px;
      }
    }
    span.clock {
      color: #606161;
      vertical-align: middle;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: 5px;
      margin-right: 15px;
    }
    span.anticon-info-circle {
      color: #b9bcbf;
      font-size: 14px;
      font-weight: bold;
      svg {
        margin-bottom: 1px;
      }
    }
  }
  .hold-clock-color-red {
    color: unset !important;
    background-color: #fee7e8;
    font-size: 12px;
    border: solid 1px #f5222d;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    vertical-align: middle;

    span.anticon-clock-circle {
      color: #f5222d !important;
      font-weight: bold;
      font-size: 16px;
      svg {
        margin: 0px;
      }
    }
    span.clock {
      color: #606161;
      vertical-align: middle;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: 5px;
      margin-right: 15px;
    }
    span.anticon-info-circle {
      color: #b9bcbf;
      font-size: 14px;
      font-weight: bold;
      svg {
        margin-bottom: 1px;
      }
    }
  }

  /* Using !important here to prevent the need to 
       duplicate styles that are already defined above */
  &.list {
    margin: 0;

    .clock {
      font-size: 12px !important;
      line-height: 1em !important;
    }

    .anticon-clock-circle {
      display: none !important;
    }
  }
}

.left-panel-hold-clock-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .reset-button {
    background-color: white;
    border-color: white;
    font-weight: bold;
    color: black;
    height: 30px;
    width: 118px;
    padding-top: 2px;
    cursor: pointer;
  }

  .reset-button:disabled {
    background-color: transparent;
    border-color: white;
    font-weight: bold;
    color: black;
    cursor: not-allowed;
  }
}

.reset-hold-clock-modal {
  .modal-header {
    border-bottom: 0px !important;
  }
  .modal-footer {
    border-top: 0px !important;
  }
}

.ant-picker-dropdown {
  z-index: 10000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
