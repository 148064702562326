div#avb-applicants {
  .avb-fullname-col {
    max-width: 180px;
    width: 180px;
  }
  .avb-phone-col {
    max-width: 120px;
    width: 120px;
    white-space: nowrap;
  }
  .avb-status-col {
    max-width: 180px;
    width: 180px;
  }
  .avb-email-col {
    max-width: 180px;
    min-width: 140px;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .avb-action-col {
    max-width: 10px;
    width: 10px;
  }
}
