div.applicant-information-modal {
  .header {
    display: flex;

    .title {
      flex: 1;
    }
    .edit-button {
      background: transparent;
      border: none;
      color: #8a8a8a;
      padding-top: 0px;
      padding-bottom: 3px;
    }
    .cancel-button {
      margin-right: 5px;
    }
  }
  .pending-application {
    text-align: center;
  }
}
